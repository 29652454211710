.aboutPage {
    margin: auto;
    margin-top: 8em;
    margin-bottom: 10em;
    width: 80%;
  }
  
  .videoContainer {
    text-align: center;
  }
  
  .video {
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 1em; /* Optional: Add some border-radius for better aesthetics */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add some shadow for better aesthetics */
  }
  
  .videoText {
    margin-top: 1em;
    font-size: 1rem;
    color: #333;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .aboutPage {
      width: 90%;
      margin-top: 6em;
      margin-bottom: 8em;
    }
  
    .video {
      max-width: 90%;
    }
  }
  
  @media (max-width: 767px) {
    .aboutPage {
      width: 100%;
      margin-top: 4em;
      margin-bottom: 6em;
    }
  
    .video {
      max-width: 100%;
    }
  
    .videoText {
      font-size: 0.9rem;
    }
  }
  