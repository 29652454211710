.butonatDiv {
    display: flex;
    margin-top: 0;
  }
  
  .buttonat {
    border: none;
    box-shadow: 0 0 10px #d3d3d3;
    background-color: white;
    color: black;
    padding: 10px;
    width: 7em;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .buttonat:hover {
    background-color: #01df93;
    color: white;
  }
  
  .butoniAddToCart {
    width: 11em;
  }
  
  
  