/* Fillimi i CSS te Slider-it te Brendeve */

.brandsSlider {
    position: relative;
    overflow: hidden;
    padding: 20px;
    margin-top: 4em;
    text-align: center;
  }
  
  .brandsSliderLabel {
    padding: 0 10vw;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0.5em;
    text-transform: capitalize;
    color: #0f243b;
  }
  
  .kornizaEBrendeve {
    padding: 0 10vw;
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
  }
  
  .kornizaEBrendeve::-webkit-scrollbar {
    display: none;
  }
  
  .kartelaEBrendit {
    flex: 0 0 auto;
    width: 250px;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-right: 6em;
    background-color: #fff;
    box-shadow: 0 0 10px #d3d3d3;
    box-sizing: border-box;
    border-radius: 15px;
    padding: auto;
  }
  
  .kartelaEBrendit:hover {
    transform: scale(1.1);
    transition: 0.1s ease-in-out;
  }
  
  .logoBrendit {
    display: flex;
    justify-content: center;
    width: 250px;
    margin-top: 1em;
    margin-bottom: 1em;
    overflow: hidden;
    cursor: pointer;
  }
  
  .logoBrendit img {
    width: 90%;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
  
  .shkoMajtas,
  .shkoDjathtas {
    border: none;
    width: 10vw;
    height: 100%;
    position: absolute;
    top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    cursor: pointer;
    z-index: 8;
  }
  
  .shkoMajtas {
    left: 0;
    transform: rotate(180deg);
  }
  
  .shkoDjathtas {
    right: 0;
  }
  
  .shkoMajtas img,
  .shkoDjathtas img {
    opacity: 0.2;
  }
  
  .shkoMajtas:hover img,
  .shkoDjathtas:hover img {
    opacity: 1;
  }
  
  /* Mbarimi i CSS te Slider-it te Brendeve */
  